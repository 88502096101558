import * as React from 'react'
import * as Urql from 'urql'
import * as PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as ClientConfig from './client-configuration'
import { FieldLabel } from './field-label'
import { FieldResponse } from './field-response'
import { Input } from './input'

const getAddressPredictions = `
query PredictedAddresesQuery($zipCode: String!, $country: String!, $language: String) {
  predictedAddresses(zipCode: $zipCode, country: $country, language: $language) {
    id
    address
  }}
`

const getAddressDetails = `
query AddressDetailsQuery($id: ID!, $language: String) {
  detailedAddress(id: $id, language: $language) {
    id
    city
    state
    street
    district
    zipCode
  }
}
`

const PlacesList = styled.ul`
  position: absolute;
  width: 318px;
  list-style-type: none;
  border: 1px solid #000;
  border-radius: 3px;
  white-space: nowrap;
  color: #000;
  background-color: #fff;
  z-index: 9999;
  ${css({
    top: ['80px', null, null, '62px'],
  })}
`

const PlaceItem = styled.li`
  display: flex;
  padding: 6px;
  overflow: hidden;
  white-space: break-spaces;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }

  &:not(:last-child) {
    border-bottom: 1px solid lightgray;
  }
`

// Google returns records with English words `Ward` and `City` it can be misleading for Japanese users that's why we replace 'Ward' with Japanese `ku` and remove 'City'
function replaceEnglishWords(str = '') {
  return str
    .replace('日本、', '')
    .replace('Ward', 'ku')
    .replace('City', '')
    .split(', ')
    .map(s => s.trim())
    .join(', ')
}

function useFormatZipCode() {
  const prevZipCodeRef = React.useRef('')

  function formatZipCode(zipCodeValue = '') {
    const zipCode = zipCodeValue.replace(/\D/g, '')
    const regex = /^(\d{3}-)?$/

    if (regex.test(prevZipCodeRef.current) && zipCode.length === 3) {
      prevZipCodeRef.current = zipCodeValue
      return zipCode
    }

    const formattedValue = zipCode.replace(/(\d{3})(\d{0,4})/, '$1-$2')
    prevZipCodeRef.current = formattedValue
    return formattedValue
  }

  return formatZipCode
}

export function ZipCodeInputField(props) {
  const [language] = ClientConfig.useLanguage()
  const formatZipCode = useFormatZipCode()
  const zipField = Forms.useField(props.scope, {
    normalize: formatZipCode,
    initialValue: '',
  })
  const i18n = I18n.useI18n()
  const streetField = Forms.useField('#/properties/street', {
    noRegister: true,
  })
  const cityField = Forms.useField('#/properties/city', {
    noRegister: true,
  })

  const [placeId, setPlaceId] = React.useState('')
  const [displayPredictions, setDisplayPredictions] = React.useState(false)

  const [
    { data: { predictedAddresses } = {} },
    executePredictionsQuery,
  ] = Urql.useQuery({
    pause: true,
    query: getAddressPredictions,
    variables: {
      country: 'JP',
      zipCode: zipField.value,
      language: language || 'ja',
    },
  })

  const [
    { data: { detailedAddress } = {}, fetching: fetchingAddress },
    executeDetailsQuery,
  ] = Urql.useQuery({
    pause: true,
    query: getAddressDetails,
    variables: {
      id: placeId,
      language: language || 'ja',
    },
  })

  function onPlaceChange(id) {
    return () => {
      setPlaceId(id)
      setDisplayPredictions(false)
    }
  }

  React.useEffect(() => {
    if (zipField?.value?.length >= 3) {
      executePredictionsQuery()
    }
  }, [zipField.value])

  React.useEffect(() => {
    if (placeId) {
      executeDetailsQuery()
    }
  }, [placeId])

  React.useEffect(() => {
    if (!fetchingAddress && detailedAddress) {
      const { state, city, district, street, zipCode } = detailedAddress
      const streetFieldValue =
        district != null
          ? `${replaceEnglishWords(city)}, ${replaceEnglishWords(
              district
            )}, ${street}`
          : `${replaceEnglishWords(city)}, ${street}`
      zipField.onChangeValue(zipCode)
      cityField.onChangeValue(state)
      streetField.onChangeValue(streetFieldValue)
      setPlaceId('')
    }
  }, [detailedAddress, fetchingAddress])

  function handleChange(event) {
    if (event.target.value.length >= 3 && !displayPredictions) {
      setDisplayPredictions(true)
    }

    if (event.target.value.length < 3 && displayPredictions) {
      setDisplayPredictions(false)
    }

    zipField.onChange(event)
  }

  return (
    <Common.Box position="relative">
      <FieldLabel>{i18n.translate(zipField.label)}</FieldLabel>
      <Input
        autoComplete="postal-code"
        placeholder={i18n.translate(zipField.placeholder)}
        valid={zipField?.errors?.length === 0}
        invalid={zipField?.errors?.length > 0}
        suppressVisualFeedback={props.suppressVisualFeedback}
        value={zipField.value}
        visited={
          ![Forms.FieldStatus.PRISTINE, Forms.FieldStatus.ABSENT].includes(
            zipField.status
          )
        }
        onChange={handleChange}
        onBlur={zipField.onBlur}
      />
      {props.suppressVisualFeedback ? null : (
        <FieldResponse scope={props.scope} />
      )}
      {displayPredictions && predictedAddresses ? (
        <PlacesList>
          {predictedAddresses.map(place => (
            <PlaceItem key={place.id} onClick={onPlaceChange(place.id)}>
              {replaceEnglishWords(place.address)}
            </PlaceItem>
          ))}
          <Common.Box
            display="flex"
            padding={0}
            alignItems="center"
            justifyContent="flex-end"
            color="#5F6368"
            fontFamily="Roboto"
          >
            {/* Styles for that should be applied are described in Google's Policies for API
                  and can be found there: https://developers.google.com/maps/documentation/places/web-service/policies#font
              */}
            <Common.Text
              fontSize={0}
              fontWeight="500"
              lineHeight="14px"
              letterSpacing="0.69px"
              textTransform="uppercase"
            >
              Powered by
            </Common.Text>
            <Common.Box
              ml={1}
              as="img"
              alt="Google"
              src={i18n.translate('google-logo')}
            />
          </Common.Box>
        </PlacesList>
      ) : null}
    </Common.Box>
  )
}

ZipCodeInputField.propTypes = {
  suppressVisualFeedback: PropTypes.bool,
  scope: PropTypes.string.isRequired,
}
