import * as R from 'ramda'

import * as Constants from '../constants'

export function dataSchema(data) {
  return {
    type: 'object',
    properties: {
      email: {
        type: 'string',
        pattern: Constants.EmailPattern,
      },
      password: {
        type: 'string',
        pattern: Constants.PasswordPatternJp,
      },
      phonenumber: {
        type: 'string',
        pattern: /^[0-9]+$/,
      },
      countryCallingCode: {
        type: 'string',
      },
      firstName: {
        type: 'string',
        pattern: Constants.katakanaAndLatinPattern,
      },
      lastName: {
        type: 'string',
        pattern: Constants.katakanaAndLatinPattern,
      },
      street: {
        type: 'string',
      },
      building: {
        type: 'string',
      },
      zip: {
        type: 'string',
        pattern: Constants.japaneseZipCodePattern,
      },
      city: {
        type: 'string',
      },
      bdayDay: {
        type: 'string',
        enum: [
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
        ],
      },
      bdayMonth: {
        type: 'string',
        enum: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
      },
      bdayYear: {
        type: 'string',
        maximum: data.maxYear,
        minimum: 1900,
      },
      terms: {
        const: true,
        type: 'boolean',
      },
    },
    required: R.filter(Boolean, [
      'bdayDay',
      'bdayMonth',
      'bdayYear',
      'city',
      'countryCallingCode',
      'email',
      'firstName',
      'lastName',
      'password',
      'phonenumber',
      'street',
      'terms',
      'zip',
    ]),
  }
}
