import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

import * as Processes from '@rushplay/processes'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as I18n from '@rushplay/i18n'

import * as Player from '../player'
import * as ServerConfiguration from '../server-configuration'
import * as Icons from '../icons'
import { Checkbox } from '../checkbox'
import { FieldBuilder } from '../field-builder'
import { InputPasswordField } from '../input-password-field'
import { RegistrationStep } from '../constants'
import { Spinner } from '../spinner'
import { SubmitButton } from '../submit-button'
import { ZipCodeInputField } from '../zip-code-input-field'

import * as uiSchema from './ui-schema'

export function RegistrationForm(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(Player.updateTrafficJunky({ event: 'REGISTRATION_FUNNEL' }))
  }, [])

  const { country } = ServerConfiguration.useContext()

  const loading = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['PHONE_VERIFICATION_LOADING'],
    })
  )

  const registrationInProgress = ReactRedux.useSelector(state =>
    Processes.isRunning(state.processes, {
      ids: ['REGISTRATION', 'LOGIN_LOADING'],
    })
  )

  if (!country.enabled) {
    return (
      <React.Fragment>
        <Common.Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          pt="2"
        >
          <Common.Box fontSize={8} color="warning">
            <Icons.Warning />
          </Common.Box>
          <Common.Box fontSize={3} p={1}>
            {i18n.translate('errors.unsupported-country')}
          </Common.Box>
        </Common.Box>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      {props.step === RegistrationStep.Credentials && (
        <React.Fragment>
          <FieldBuilder
            uiSchema={uiSchema.step1}
            name="registration-step1"
            initialValues={{
              '#/properties/countryCallingCode': `+${country.countryCode}`,
            }}
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/password': {
                  return InputPasswordField
                }

                default: {
                  return null
                }
              }
            }}
          />
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <SubmitButton
              onlyScopes={[
                '#/properties/email',
                '#/properties/password',
                '#/properties/phonenumber',
              ]}
              loading={loading}
            >
              {loading ? (
                <Common.Box
                  maxWidth="30px"
                  maxHeight="30px"
                  mt="-15px"
                  mb="5px"
                >
                  <Spinner />
                </Common.Box>
              ) : (
                i18n.translate('register.step1.cta')
              )}
            </SubmitButton>
          </Common.Box>
          <Common.Box
            pt={1}
            pb={1}
            textAlign="center"
            lineHeight="1.6"
            fontSize={2}
          >
            <Common.Text color="darken-gray">
              {i18n.translate('registration.switch-method.content')}
            </Common.Text>
            <Common.Text
              textDecoration="underline"
              font="head"
              fontWeight="bold"
            >
              <Common.Link to="?login=me" data-testid="register-page-login">
                {i18n.translate('registration.switch-method.cta')}
              </Common.Link>
            </Common.Text>
          </Common.Box>
        </React.Fragment>
      )}

      {props.step === RegistrationStep.Identity && (
        <React.Fragment>
          <FieldBuilder
            uiSchema={uiSchema.step2}
            name="registration-step2"
            getCustomField={scope => {
              switch (scope) {
                case '#/properties/terms': {
                  return TermsCheckbox
                }

                case '#/properties/zip': {
                  return ZipCodeInputField
                }

                default: {
                  return null
                }
              }
            }}
          />
          {R.not(R.isEmpty(props.errors)) && (
            <Common.Box display="grid" gridGap={1} pt={1}>
              {R.map(error => {
                return (
                  <Common.Box key={error} color="danger" fontSize={1}>
                    {i18n.translate(`errors.registration.${error}`)}
                  </Common.Box>
                )
              }, props.errors)}
            </Common.Box>
          )}
          <Common.Box pt={2} textAlign="center">
            <SubmitButton disabled={registrationInProgress}>
              {registrationInProgress ? (
                <Common.Box
                  maxWidth="30px"
                  maxHeight="30px"
                  mt="-15px"
                  mb="5px"
                >
                  <Spinner />
                </Common.Box>
              ) : (
                i18n.translate('register.step2.cta')
              )}
            </SubmitButton>
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

RegistrationForm.propTypes = {
  errors: PropTypes.array,
  step: PropTypes.number.isRequired,
}

function AllowCheckbox(props) {
  const i18n = I18n.useI18n()
  const field = Forms.useField(props.scope)

  return (
    <Checkbox
      id={field.name}
      name={field.name}
      checked={field.value}
      label={props.label ? props.label : i18n.translate(field.label)}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

AllowCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scope: PropTypes.string,
}

function TermsCheckbox(props) {
  const field = Forms.useField(props.scope)
  return (
    <AllowCheckbox
      label={<TermsLabel label={field.label} to="/terms-and-conditions" />}
      scope={props.scope}
    />
  )
}

TermsCheckbox.propTypes = {
  scope: PropTypes.string,
}
function TermsLabel(props) {
  const i18n = I18n.useI18n()

  return (
    <Common.Box display="grid" gridGap={1}>
      <Common.Box
        style={{ textDecoration: 'underline' }}
        fontSize={1}
        gridGap={0}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
      >
        <ReactRouter.Link to="/terms-and-conditions">
          {i18n.translate('terms-and-conditions')}
        </ReactRouter.Link>
        <ReactRouter.Link to="/privacy-policy">
          {i18n.translate('privacy-policy')}
        </ReactRouter.Link>
      </Common.Box>
      <Common.Box style={{ whiteSpace: 'pre-wrap' }}>
        {i18n.translate(props.label)}
      </Common.Box>
    </Common.Box>
  )
}

TermsLabel.propTypes = {
  label: PropTypes.string.isRequired,
}
