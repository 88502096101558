import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'
import React from 'react'

import * as I18n from '@rushplay/i18n'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Processes from '@rushplay/processes'

import * as ServerConfiguration from '../server-configuration'
import { ProgressBar } from '../progress-bar'
import { QueryDrawer } from '../query-drawer'
import { RegistrationForm } from '../registration-form-casinoskyjp'
import { RegistrationStep } from '../constants'
import { fullWidthToHalfWidth } from '../full-width-to-half-with'

import { dataSchema } from './data-schema'

export function RegistrationDrawer(props) {
  const i18n = I18n.useI18n()
  const dispatch = ReactRedux.useDispatch()
  const [step, setStep] = React.useState(RegistrationStep.Credentials)
  const [codeSentTo, setCodeSentTo] = React.useState(null)
  const [errors, setErrors] = React.useState([])
  const fingerprint = Herz.Seon.useFingerprint()
  const { countries, country, locale } = ServerConfiguration.useContext()

  const schema = React.useMemo(
    () =>
      dataSchema({
        countryCallingCode: R.map(
          R.toString,
          R.pluck('countryCode', countries)
        ),
        country: R.pluck('name', countries),
        maxYear: new Date(Date.now()).getFullYear() - 18,
      }),
    [countries]
  )

  function handleSubmit(data) {
    dispatch(Processes.start('REGISTRATION'))
    const normalizedData = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [
        key,
        typeof value === 'string' ? fullWidthToHalfWidth(value) : value,
      ])
    )

    const userData = {
      affiliateClickId: props.affiliateClickId,
      affiliateSubId: props.affiliateSubId,
      allowEmail: data.promotional,
      allowSms: data.promotional,
      birthdate: `${data.bdayDay}/${data.bdayMonth}/${data.bdayYear}`,
      city: normalizedData.city,
      clientType: props.clientType,
      countryCallingCode: data.countryCallingCode,
      countryCode: country.alpha2,
      currency: props.currency,
      email: normalizedData.email,
      firstName: normalizedData.firstName,
      // Hardcodes gender because it's required on BE, but we don't care
      gender: 'Male',
      generate_username: true,
      language: locale.language,
      lastName: normalizedData.lastName,
      mobile: normalizedData.phonenumber,
      netrefererBTag: props.btag,
      password: normalizedData.password,
      passwordConfirmation: normalizedData.password,
      phoneVerificationCode: normalizedData.phoneVerificationCode,
      street: `${normalizedData.street} ${normalizedData.building}`,
      utmCampaign: props.utmCampaign,
      utmMedium: props.utmMedium,
      utmSource: props.utmSource,
      zip: normalizedData.zip,
      seon_session: fingerprint.value,
    }

    props.onSubmit(userData, setErrors)
  }

  function handlePhoneValidation(data) {
    const userData = {
      countryCallingCode: `+${data.countryCallingCode}`,
      phone: data.phonenumber,
      language: locale.language,
    }

    function failure(errors) {
      setErrors(errors)
      setCodeSentTo('')
    }

    function success() {
      setErrors([])
      setStep(RegistrationStep.Identity)
      setCodeSentTo(`${data.countryCallingCode}${data.phonenumber}`)
    }

    props.onPhoneValidation(userData, failure, success)
  }

  if (fingerprint.fetching) {
    return null
  }

  return (
    <Forms.Provider
      name="register"
      schema={schema}
      onSubmit={(formErrors, data) => {
        if (R.isEmpty(formErrors) && step > RegistrationStep.Credentials) {
          handleSubmit(data)
        }

        if (
          R.not(
            R.equals(
              codeSentTo,
              `${data.countryCallingCode}${data.phonenumber}`
            )
          )
        ) {
          props.onRegistrationStarted()
          handlePhoneValidation(data)
        }

        if (
          data.email &&
          data.password &&
          data.phonenumber &&
          data.countryCallingCode &&
          codeSentTo &&
          R.equals(codeSentTo, `${data.countryCallingCode}${data.phonenumber}`)
        ) {
          setStep(RegistrationStep.Identity)
        }
      }}
    >
      <QueryDrawer
        onSecondaryAction={
          step > RegistrationStep.Credentials
            ? () => setStep(RegistrationStep.Credentials)
            : null
        }
        activeQueryName="register"
        title={i18n.translate('register.title')}
      >
        <Common.Box pb={6} color="g-text">
          <ProgressBar step={step} steps={2} />
          <RegistrationForm errors={errors} step={step} />
        </Common.Box>
      </QueryDrawer>
    </Forms.Provider>
  )
}

RegistrationDrawer.propTypes = {
  affiliateClickId: PropTypes.string,
  affiliateSubId: PropTypes.string,
  btag: PropTypes.string,
  clientType: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  utmCampaign: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  onPhoneValidation: PropTypes.func.isRequired,
  onRegistrationStarted: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
