import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import kebabCase from 'lodash.kebabcase'

import * as Analytics from '@rushplay/analytics'
import * as Api from '@rushplay/api-client'
import * as Notifications from '@rushplay/notifications'
import * as Processes from '@rushplay/processes'

import * as CombinedSelectors from '../combined-selectors'
import * as Player from '../player'
import * as Session from '../session'
import * as Configuration from '../configuration'

import { RegistrationDrawer as Component } from './registration-drawer'

export const RegistrationDrawer = ReactRedux.connect(
  state => ({
    affiliateClickId: CombinedSelectors.getAffiliateClickId(state),
    affiliateSubId: Analytics.getSubId(state.analytics),
    btag: Analytics.getBtag(state.analytics),
    clientType: Configuration.getClientType(state.configuration),
    currency: Player.getCurrency(state.player),
    utmCampaign: Analytics.getUtmCampaign(state.analytics),
    utmMedium: Analytics.getUtmMedium(state.analytics),
    utmSource: Analytics.getUtmSource(state.analytics),
  }),
  (dispatch, props) => ({
    onSubmit: (userData, onFailure) =>
      dispatch(
        Api.registerPlayer(userData, {
          success: () => {
            dispatch([
              Player.updateTrafficJunky({
                event: 'REGISTRATION_CONFIRMATION',
                cb: Math.floor(Math.random() * 1000000000000),
              }),
              Processes.stop('REGISTRATION'),
            ])
            return Session.login(
              userData.email,
              userData.password,
              props.clientType
            )
          },
          failure: res => {
            dispatch(Processes.stop('REGISTRATION'))
            if (res.status >= 500) {
              return Notifications.add({
                message: `errors.${kebabCase(res.value.message)}`,
                level: 'error',
              })
            }
            if (R.not(R.isEmpty(res.value.errors))) {
              // converts errors into usable format
              const errors = R.pipe(
                R.map(error => R.head(error)),
                R.toPairs,
                R.map(
                  item =>
                    `${kebabCase(R.head(item))}.${kebabCase(R.tail(item))}`
                )
              )(res.value.errors)

              onFailure(errors)
            }
          },
          version: 1,
        })
      ),
    onPhoneValidation: (data, onFailure, onSuccess) =>
      dispatch(
        Api.validatePhoneNumber(data.countryCallingCode, data.phone, {
          success: onSuccess,
          failure: res => {
            onFailure([`phone-validation.${kebabCase(res.value.message)}`])
          },
          version: 2,
        })
      ),
    onRegistrationStarted: () =>
      dispatch(Analytics.registrationStarted({ registrationType: 'inhouse' })),
  })
)(Component)

// for @loadable/components
export default RegistrationDrawer
